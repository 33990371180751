<template>
  <section id="visitors-chart" :class="{comparing: compareChartIsActive}">


    <card id="main-chart" icon="chart-area"
          :headline="$t('visitors.periodic.headline')"
          :description="$t('visitors.periodic.description')"
    >
      <div slot="header" class="clearfix">
        <div class="card-compare" v-tooltip="$t('visitors.compare.compareToggleTooltip')">
          <i class="fa" :class="[compareChartIsActive ? 'fa-eye' : 'fa-low-vision']" @click="toggle"/>
        </div>
        <interval/>
      </div>

      <article class="is-chart"></article>
    </card>

    <template v-if="compareChartIsActive">

      <card id="compare-chart" class="is-chart" icon="chart-area"
            :headline="$t('visitors.compare.headline')"
            :description="$t('visitors.periodic.description')"
      >
        <article class="is-chart"></article>
      </card>
      <card id="details-table">

      </card>
    </template>

  </section>
</template>

<script>
  import Interval from "@/app/datepicker/components/Interval";
  export default {
    name: "VisitorsChart",
    components: {Interval},
    data: () => ({
      compareChartIsActive: false,
      selectedCompareMethod: 'samePeriodLastYear',
    }),

    methods: {
      toggle(){
        this.compareChartIsActive = !this.compareChartIsActive
        this.$emit('toggle', this.compareChartIsActive)
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '~@/assets/scss/_mixins.scss';


  #visitors-chart {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "main-chart";

    .card-compare {
      height: 40px;
      width: 40px;
      float: right;
      position: relative;

      i {
        cursor: pointer;
        font-size: 18px;
        @include absoluteCenter(50%, 50%);
      }
    }

    &.comparing {
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "compare-chart main-chart" "details-table details-table";
      gap: 30px;
    }

    #main-chart {
      grid-area: main-chart;
    }

    #compare-chart {
      grid-area: compare-chart;
    }

    #details-table {
      grid-area: details-table;
    }

    .is-chart {
      min-height: 300px;
    }
  }

</style>
